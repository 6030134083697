import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"700"},model:{value:(_vm.edit.dialog),callback:function ($$v) {_vm.$set(_vm.edit, "dialog", $$v)},expression:"edit.dialog"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,[_vm._v("Edit Bill/Delivery for "+_vm._s(this.fuelText(this.edit.data ? this.edit.data.fuel : ''))+" "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.edit.dialog = !_vm.edit.dialog}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VContainer,[_c('base-form',{attrs:{"update":"","submitButton":"Update","data":_vm.edit.data},on:{"submit":_vm.updateHVACDelivery,"cancel":function($event){_vm.edit.dialog = false}}})],1)],1)],1),_c(VDialog,{attrs:{"max-width":"400"},model:{value:(_vm.dlt.dialog),callback:function ($$v) {_vm.$set(_vm.dlt, "dialog", $$v)},expression:"dlt.dialog"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle),_c(VCardText,[_vm._v(" Are you sure? Do you want to delete this item? ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteItem()}}},[_vm._v("Yes")]),_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.dlt.dialog = false}}},[_vm._v("No")]),_c(VSpacer)],1)],1)],1),_c('base-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.itemsComputed,"sort-by":"date","sort-desc":true,"dense":""},scopedSlots:_vm._u([{key:"header.fuel",fn:function(ref){
var header = ref.header;
return [_c('span',[_c('span',[_vm._v(" "+_vm._s(header.text)+" ")]),_c('table-filter',{attrs:{"iconColor":"white","itemStyle":"","items":_vm.sourceToObject(_vm.availableHVACSources)},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)]}},{key:"item.fuel",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({on:{"click":function($event){return _vm.setFilter(_vm.filter ? '' : item.fuel)}}},on),[_c('fuel-chip',{attrs:{"small":"","shortName":"","value":_vm.getSource(item.fuel)}})],1)]}}],null,true)},[(!_vm.filter)?_c('span',[_vm._v(" Click to see only "+_vm._s(_vm.getSource(item.fuel) ? _vm.getSource(item.fuel).text : '')+" rows ")]):_c('span',[_vm._v("Click to see all rows")])])]}},{key:"item.btu",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.fmtBtu(item.btu)))])]}},{key:"item.units",fn:function(ref){
var item = ref.item;
return [(item.units)?_c('div',[_vm._v(_vm._s(item.units)+" "+_vm._s(_vm.getSource(item.fuel).unitShort))]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('DisplayFile',{attrs:{"file":item.bill}}),_c('UpdateDelete',{on:{"update":function($event){return _vm.editItem(item)},"delete":function($event){return _vm.showDeleteDialog(item)}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }