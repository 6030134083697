<template>
  <v-form v-model="valid" ref="form">
    <div class="d-flex flex-wrap checkbox-group" style="margin-bottom: 7px; margin-top: -18px">
      <div>
        <v-radio-group
          @change="onFuelChange"
          v-show="!HVACLoading"
          v-model="formData.fuel"
          :rules="rules.requiredRule"
        >
          <div class="d-flex flex-wrap">
            <div v-for="(source, index) in selectedSources" :key="index">
              <v-radio class="mr-3 mt-2" :value="source.value" :label="source.text"></v-radio>
            </div>
            <div class="mt-2 error--text" v-if="!formData.fuel && selectedSources.length">
              Select one to continue
            </div>
          </div>
        </v-radio-group>
      </div>
    </div>
    <div v-if="selectedSources.length == 0 && !HVACLoading">
      <v-alert type="info" text> Specify fuel source to continue </v-alert>
    </div>
    <div v-if="HVACLoading" class="pa-5 text-center">
      <base-progress-circular></base-progress-circular>
    </div>

    <v-fade-transition>
      <div v-show="formData.fuel && showHVACForm">
        <v-row>
          <v-col cols="6" sm="6" md="3" lg="3">
            <date-field label="Date" v-model="formData.date"></date-field>
          </v-col>
          <v-col
            cols="6"
            md="3"
            lg="3"
            v-show="sourceInfo(formData.fuel) && sourceInfo(formData.fuel).unit_field"
          >
            <v-text-field
              autofocus
              :label="sourceInfo(formData.fuel) ? sourceInfo(formData.fuel).unit : ''"
              type="number"
              outlined
              v-model="formData.units"
              :rules="
                sourceInfo(formData.fuel) && sourceInfo(formData.fuel).unit_field
                  ? rules.requiredRule
                  : []
              "
              :append-icon="sourceInfo(formData.fuel) ? sourceInfo(formData.fuel).icon : ''"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="3" lg="3">
            <v-text-field
              label="Total Cost"
              prefix="$"
              type="number"
              outlined
              append-icon="mdi-calculator"
              :rules="(rules.positiveNumber, rules.requiredRule)"
              v-model="formData.cost"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="3">
            <base-combobox
              label="By"
              :items="HVACVendors([formData.fuel])"
              :key="formData.fuel"
              v-model="formData.supplier"
              outlined
            ></base-combobox>
          </v-col>
        </v-row>

        <v-row class="mt-n7">
          <v-col cols="9">
            <v-checkbox
              v-if="formData.fuel"
              :label="uploadLabel(formData.fuel)"
              v-model="formData.bill.exists"
            ></v-checkbox>
            <v-slide-y-transition>
              <file-upload
                v-if="formData.bill.exists"
                :report="formData.bill"
                :file.sync="files.bill"
                :showDescription="true"
                :description.sync="descriptions.bill"
                documentDescription="Document Description (can be edited)"
              ></file-upload>
            </v-slide-y-transition>
          </v-col>
          <v-col cols="3" class="cost-per-item" v-show="formData.cost && formData.units">
            <div class="" v-if="costPerItem(this.formData)">
              {{ costPerItem(this.formData) }} $/{{
                sourceInfo(formData.fuel) ? sourceInfo(formData.fuel).unitPerItem : ''
              }}
            </div>
          </v-col>
        </v-row>

        <div class="text-center">
          <FormAction @click="submitData()" @cancel="cancel()" :update="update" />
        </div>
      </div>
    </v-fade-transition>
  </v-form>
</template>

<script>
import hvac from '@/mixins/hvac';
import parcel from '@/mixins/parcel';

import rules from '@/mixins/rules';
import storage from '@/mixins/storage';
import { mapGetters } from 'vuex';
export default {
  mixins: [hvac, parcel, rules, storage],
  props: {
    submitButton: {
      type: String,
      default: 'Save'
    },
    data: {},
    update: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FileUpload: () => import('@/components/FileUpload.vue'),
    DateField: () => import('@/components/Core/DateField.vue'),
    FormAction: () => import('@/components/Feature/FormAction.vue')
  },

  data: () => ({
    files: {},
    descriptions: { bill: '' },
    formData: {},
    defaultForm: {
      date: '',
      fuel: '',
      unit_of_measure: '',
      cost: null,
      units: '',
      supplier: '',
      bill: {}
    },
    valid: true,
    origData: null,
    loading: false
  }),

  computed: {
    fuel() {
      return this.formData.fuel;
    },
    defaultSource() {
      return this.selectedSources[0] ? this.selectedSources[0].value : '';
    },
    supplier() {
      if (this.HVACInformation && this.HVACInformation[this.defaultSource]) {
        return this.HVACInformation[this.defaultSource].supplier;
      } else {
        return '';
      }
    },
    getSelectedSource() {
      let res = [];
      if (this.formData.fuel) {
        res = this.HVACSources.filter(e => e.value == this.formData.fuel);
      }
      return res[0];
    },
    showUnitField() {
      let f = this.getSelectedSource;
      let res = false;
      if (f) {
        res = f.unit_field;
      }
      return res;
    },
    getUnit() {
      if (!this.getSelectedSource) {
        return '';
      } else {
        return this.getSelectedSource.unit;
      }
    },
    showHVACForm() {
      if (this.data) {
        return true;
      } else {
        return this.$store.getters.showHVACForm;
      }
    },
    ...mapGetters([
      'HVACLoading',
      'HVACDefaultSources',
      'HVACSources',
      'latestParcel',
      'HVACFilter',
      'HVACInformation'
    ])
  },

  beforeMount() {
    this.setDefaultValue();
  },
  methods: {
    sourceInfo(s) {
      return this.HVACSources.filter(e => e.value == s)[0];
    },
    setDefaultValue(cancel) {
      if (this.data) {
        this.formData = this.copyObject(this.origData);
      } else {
        this.formData = this.copyObject(this.defaultForm);
        this.formData.date = '';
        if (cancel) {
          this.formData.fuel = '';
        }
      }
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    isSelectedSource(source) {
      if (!this.HVACInformation) {
        return false;
      }
      let info = { ...this.HVACDefaultSources, ...this.HVACInformation };

      return info[source].value;
    },
    async uploadBill() {
      if (this.files.bill) {
        let bill = await this.uploadFile(this.files.bill, {
          feature: 'hvac',
          parcelID: this.$route.params.id || '',
          description: this.descriptions.bill
        });
        this.formData.bill = { ...this.formData.bill, ...bill };
        this.files.bill = null;
      }
    },
    setData() {
      if (this.data) {
        this.origData = this.copyObject(this.data);
        this.formData = this.copyObject(this.data);
        this.descriptions.bill = this.data.bill.description || '';
      } else {
        this.setDefaultInformation();
      }
    },
    setDefaultInformation() {
      if (this.data) {
        return;
      }
      this.formData.supplier = this.supplier;
      // this.formData.fuel = this.defaultSource;

      this.defaultForm.supplier = this.supplier;
      this.defaultForm.fuel = this.defaultSource;

      this.setUnit();
    },
    setDescription() {
      if (this.latestParcel) {
        let description = `${this.latestParcel.street_number} ${this.latestParcel.street_name}`;
        if (this.formData.fuel) {
          description += '_';
          description += `${this.formData.fuel}`;
        }
        this.descriptions.bill = description;
      }
    },
    onFuelChange() {
      if (this.formData.fuel && this.HVACInformation && this.HVACInformation[this.formData.fuel]) {
        this.formData.supplier = this.HVACInformation[this.formData.fuel].supplier;
        this.defaultForm.supplier = this.HVACInformation[this.formData.fuel].supplier;
        this.defaultForm.fuel = this.formData.fuel;

        this.setDescription();
        this.setUnit();
        this.$store.commit('setHVACFilter', this.formData.fuel);
      }
    },
    async submitData() {
      this.loading = true;
      this.$refs.form.validate();
      if (this.valid) {
        await this.uploadBill();
        if (this.formData.bill.exists && this.formData.bill.url && this.descriptions.bill) {
          this.formData.bill.description = this.descriptions.bill;
          this.updateMetadata(this.formData.bill.url, {
            description: this.formData.bill.description
          });
        }
        this.submit();
      }
      this.loading = false;
    },
    submit() {
      this.$emit('submit', this);
    },
    cancel() {
      this.setDefaultValue(true);
      this.$emit('cancel');
      this.$store.commit('setHVACFilter', '');
    },
    setUnit() {
      let unit = this.HVACSources.filter(e => e.value == this.formData.fuel);
      if (unit[0]) {
        this.formData.unit_of_measure = unit[0].unit;
        this.defaultForm.unit_of_measure = unit[0].unit;
      }
    },
    uploadLabel(fuel) {
      return `I have ${
        this.sourceInfo(fuel) && this.isVowel(this.sourceInfo(fuel).value[0]) ? 'an' : 'a'
      } ${this.sourceInfo(fuel) ? this.sourceInfo(fuel).text : ''} bill to archive`;
    },
    onFilterChange() {
      this.formData.fuel = this.HVACFilter;
      this.$refs.form.resetValidation();
    }
  },
  watch: {
    data: {
      immediate: true,
      handler: 'setData'
    },
    information: {
      handler: 'setDefaultInformation'
    },
    fuel: {
      handler: 'onFuelChange'
    },
    HVACFilter: {
      handler: 'onFilterChange'
    }
  }
};
</script>

<style scope>
.cost-per-item {
  margin-top: 1.3rem !important;
  text-align: right;
}
</style>
