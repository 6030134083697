import user from '@/mixins/user'
import { hvacRef } from '../firebase/db'
import { mapGetters } from 'vuex'
import { useDocumentHistory } from '../services/documentHistory'
export default {
  mixins: [user],
  computed: {
    selectedSources() {
      let s = []
      let i = this.$store.getters.HVACInformation
      if (i) {
        let sources = this.copyObject(this.$store.getters.HVACSources)
        sources = sources.map(e => e.value)
        for (let source of sources) {
          if (i[source] && i[source].value) {
            s.push(this.getSource(source))
          }
        }
      }
      return s
    },
    ...mapGetters(['HVACDelivery', 'HVACSources'])
  },
  methods: {
    async getHVACData(parcelId) {
      let res = await hvacRef.doc(parcelId).get()
      if (res.exists) {
        return res.data()
      } else {
        return null
      }
    },
    async saveHVACData(parcelId, data) {
      if (!data) {
        data = this.copyObject(this.$store.getters.HVACData)
      }
      if (!parcelId) {
        parcelId = this.$route.params.id
      }
      await hvacRef.doc(parcelId).set(data).then(()=>{
        const {addDocHistory} = useDocumentHistory()
        addDocHistory({
          id: parcelId,
          data,
          docCollection: hvacRef.id
        })
      })
    },
    HVACVendors(services) {
      let res = this.$store.getters.HVACVendors
      if (services) {
        res = res.filter(e => {
          return services.some(f => e.services.includes(f))
        })
      }
      res = res.map(e => e.company)
      return res
    },
    fuelText(fuel) {
      let f = this.$store.getters.HVACSources.filter(e => e.value == fuel)
      if (f[0]) {
        return f[0].text
      }
      return fuel
    },
    costPerItem(item) {
      if (item.cost && item.units) {
        if (item.units == 0) {
          return ''
        }
        return Number(item.cost / item.units).toFixed(2)
      } else {
        return null
      }
    },
    getSource(fuel) {
      return this.HVACSources.filter(e => e.value == fuel)[0]
    },
    sourceToObject(sources) {
      let tmp = [...sources]
      for (let i in tmp) {
        tmp[i] = this.getSource(tmp[i])
      }
      return tmp
    },
    calcBtu(item, conversion) {
      let s = this.getSource(item.fuel)
      let btu = Math.round(Number(item.units) * Number(s.btu))

      switch (conversion) {
        case 'M':
          btu = btu / 1000
          break
        case 'MM':
          btu = btu / (1000 * 1000)
          break
        default:
          break
      }
      return btu
    }
  }
}
