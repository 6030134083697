<template>
  <v-form v-model="valid" ref="form">
    <v-row class="ml-3 mt-1">
      <div class="d-flex checkbox-group flex-wrap">
        <span
          >Which of these energy/fuel sources are used in this building? (check all that
          apply)</span
        >
        <v-checkbox
          class="mr-3"
          v-for="(source, index) in sources"
          :key="index"
          :label="source.text"
          v-model="formData[source.value].value"
        ></v-checkbox>
      </div>
    </v-row>
    <div v-for="(source, index) in sources" :key="index">
      <base-combobox
        v-if="formData[source.value].value"
        :items="HVACVendors([source.value])"
        v-model="formData[source.value].supplier"
        outlined
        :label="`${source.text} Supplier`"
      ></base-combobox>
    </div>

    <!-- <base-combobox
      v-if="formData.propane.value"
      :items="HVACVendors(['propane'])"
      v-model="formData.propane.supplier"
      outlined
      label="Propane Supplier"
    ></base-combobox> -->
    <v-textarea v-model="formData.note" outlined label="Notes" rows="1"></v-textarea>
    <div class="text-center">
      <FormAction @click="submit()" @cancel="cancel()" :update="!!data" />
    </div>
  </v-form>
</template>

<script>
import hvac from '@/mixins/hvac';
import parcel from '@/mixins/parcel';
export default {
  mixins: [hvac, parcel],
  props: {
    parcel: Object
  },
  components: {
    FormAction: () => import('@/components/Feature/FormAction.vue')
  },
  data: () => ({
    formData: {},
    valid: true
  }),
  computed: {
    data() {
      return this.$store.getters.HVACInformation;
    },
    sources() {
      return this.$store.getters.HVACSources;
    },
    defaultValue() {
      return this.$store.getters.HVACDefaultSources;
    }
  },
  beforeMount() {
    this.formData = this.copyObject(this.defaultValue);
  },
  mounted() {
    this.setData();
  },
  methods: {
    setData() {
      if (this.data) {
        this.formData = { ...this.formData, ...this.copyObject(this.data) };
      }
    },
    cancel() {
      this.formData = {
        ...this.copyObject(this.defaultValue),
        ...this.copyObject(this.data)
      };
      this.$emit('cancel');
    },
    submit() {
      let data = this.copyObject(this.formData);
      this.$store.commit('updateHVACInformation', data);
      this.saveHVACData();

      this.$emit('submit');

      this.updateMetrics({ feature: 'hvac_information', action: 'update' });
    }
  },
  watch: {
    data: {
      immediate: 'true',
      handler: 'setData'
    }
  }
};
</script>

<style></style>
